import * as ReactDOMClient from 'react-dom/client'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithCustomToken, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
// import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import './index.css'
import Landing from './Landing'
import Dashboard from './Dashboard'

const firebaseConfig = {
    apiKey: "AIzaSyC8XMjjnIT4Gs2FJMhRwT6sPWktw1vphE0",
    authDomain: "redeemit-id.firebaseapp.com",
    projectId: "redeemit-id",
    storageBucket: "redeemit-id.appspot.com",
    messagingSenderId: "392554962259",
    appId: "1:392554962259:web:e8b191d7b3a6ff800555a6"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
// const functions = getFunctions(app)
let clientId = "ca_LOwXkHJRTHc5Oi1W0HIhPtnapNllPk2W"

if (process.env.NODE_ENV === "development") {
    connectAuthEmulator(auth, "http://localhost:9099")
    connectFirestoreEmulator(db, 'localhost', 8080)
    // connectFunctionsEmulator(functions, "localhost", 5001)
    clientId = "ca_LOwX1uKvOzGAK3bAKDnvwmBJHrLndjkU"
}

const container = document.getElementById('app')
const root = ReactDOMClient.createRoot(container)
var urlParams = new URLSearchParams(window.location.search)

if (urlParams.has('t')) {
    const token = urlParams.get('t')
    signInWithCustomToken(auth, token)
    .catch(error => {
        console.log(error)
    })
    .finally(() => {
        urlParams.delete('t')
    })
}

onAuthStateChanged(auth, (user) => {
    if (user) {
        root.render(<Dashboard uid={user.uid} db={db} auth={auth} />)
    } else {
        root.render(<Landing clientId={clientId} />)
    }
})
