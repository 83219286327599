import React, {useState, useEffect} from 'react'
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore"
import { Switch } from '@headlessui/react'

export default function Products({uid, db}) {
    const [products, setProducts] = useState(null)

    useEffect(() => {
        try {
            return onSnapshot(collection(db, `connectedAccounts/${uid}/products`), (querySnapshot) => {
                setProducts(querySnapshot.docs)
            })
        } catch (error) {
            console.log(error)
        }
    },[uid, db])

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function toggleLive(productId, newStatus) {
        const docRef = doc(db, `connectedAccounts/${uid}/products/${productId}`)
        try {
            updateDoc(docRef, {redeemitLive: newStatus})
        } catch (error) {
            console.log(error)
        }
    }
      
    return (
        <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
            <li key={"header"}>
                <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">Product fulfillment</div>
                    <div>Live on Redeemit</div>
                </div>
            </li>
            {products && products.map((product) => (
                <li key={product.id}>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-center">
                            <div className="flex-shrink-0">
                                <img className="h-12 w-12 rounded-lg" src={product.data().images[0]} alt="" />
                            </div>
                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                    <p className="font-medium text-gray-900 truncate">{product.data().name}</p>
                                    <p className="mt-1 flex items-center text-sm text-gray-500 truncate">
                                        Single use codes (<a href="#" className="text-zeal-600 font-semibold">edit</a>)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Switch
                                checked={product.data().redeemitLive || false}
                                onChange={() => toggleLive(product.id, !(product.data().redeemitLive || false))}
                                className={classNames(
                                    product.data().redeemitLive ? 'bg-zeal-600' : 'bg-gray-200',
                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zeal-500'
                                )}
                                >
                                <span className="sr-only">Use setting</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                    product.data().redeemitLive ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                    )}
                                />
                            </Switch>
                        </div>
                    </div>
                </li>
            ))}
      </ul>
    )
}
