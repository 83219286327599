import { ReactComponent as StripeButton } from './assets/stripeButton.svg'
import { ReactComponent as Logo } from './assets/redeemit_logo.svg'
import { AnnotationIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'

const transferFeatures = [
  {
    id: 1,
    name: 'Connect Redeemit to Stripe',
    description:
      'Set a redemption count and expiration date for any of your Stripe products and Redeemit takes care of sending the codes.',
    icon: ScaleIcon,
  },
  {
    id: 2,
    name: 'Sell via Stripe',
    description:
      'Use the powerful Stripe API or Stripe Checkout to sell your products, however you like to sell them.',
    icon: LightningBoltIcon,
  },
  {
    id: 3,
    name: 'Redeem codes and grant access',
    description:
      'Redeem codes using our QR code scanning app, tap to redeem list, or API.',
    icon: LightningBoltIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: 'Automated fulfillment',
    description:
      'Once connected, when customers buy your products, Redeemit will automatically send them an email with their codes.',
    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: 'Multiple redemptions & expiration dates',
    description:
      'For each product or subscription, set the number of redemptions allowed per code and optionally, an expiration date.',
    icon: AnnotationIcon,
  },
  {
    id: 3,
    name: 'Customizable customer notification emails',
    description:
      'Codes are sent to buyers immediately after they make their purchase, with your own logo, colors, and copy.',
    icon: MailIcon,
  },
  {
    id: 4,
    name: 'Codes - casual or dressed up',
    description:
      'Choose to display them as text or as QR codes, including the option to add to Apple Wallet',
    icon: MailIcon,
  },
  {
    id: 5,
    name: 'Lovable redemption tools',
    description:
      'Easy to use redemption tools are our craft, and you\'ll love using them.',
    icon: MailIcon,
  },
]

export default function Landing({clientId}) {
  return (
    <div className="py-16 bg-zeal-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">

        <div className="relative">
            <Logo className='h-16 w-auto m-auto' />
          
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl">
            Easily and affordably sell event tickets, e-books, courses, software, paywall access, digital downloads and more.
          </p>
        </div>
        <div>
        <a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_only`}>
            <StripeButton className='mt-8 h-12 m-auto' />
        </a>
        </div>
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-bold tracking-tight sm:text-3xl">
              How it works
            </h3>
            <p className="mt-3 text-lg">
                Redeemit is a Stripe extension which automatically fulfills purchases with redeemable codes.  
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-zeal-600 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <img
              className="relative mx-auto"
              width={490}
              src="https://tailwindui.com/img/features/feature-example-1.png"
              alt=""
            />
          </div>
        </div>



        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-bold tracking-tight sm:text-3xl">Why it's better</h3>
              <p className="mt-3 text-lg">
                Redeemit focuses on one thing -- generating and redeeming codes -- and does it well. Our tight integration allows you to fully lean on Stripe for all things e-commerce including flexible products and subscriptions, payment processing, checkout forms, refunds, taxes, coupon codes, and much more.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-zeal-600 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              
              <img
                className="relative mx-auto"
                width={490}
                src="https://tailwindui.com/img/features/feature-example-2.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}